
































































































































/* tslint:disable:no-console */
import { Component, Vue } from "vue-property-decorator";
import SearchInput from "@/components/widget/searchInput.vue";

import mixins from "@/mixin/index.ts";
import routerMixin from "@/mixin/router-mixin.ts";
import { getHomePageData } from "@/api";

import AppBlockSmall from "@/components/block/App-block-small.vue";
import AppBlockNormal from "@/components/block/App-block-normal.vue";
import ImgBlockNormal from "@/components/block/Img-block-normal.vue";

import BlockBox from "@/components/common/Block-box.vue";
import utils from "@/utils";

// @Component 修饰符注明了此类为一个 Vue 组件
@Component({
  name: "SearchDetail",
  components: {
    SearchInput,
    BlockBox,
    AppBlockSmall,
    AppBlockNormal,
    ImgBlockNormal
  },
  mixins: [mixins, routerMixin]
})
export default class SearchDetails extends Vue {
  public SearchParams: any = {
    isFocus: true,
    placeholderText: "",
    showKeyWord: false
  };

  private getHotBlock: any = {};
  private getHotBlock2: any = {};
  private getTagBlock = {};
  private getBlurGameBlock = {};
  private getFocusGameBlock = {};
  private searchState = false;
  private focusState = false;
  private keyWord = "";
  private rankId = "";
  private start = 0;
  private notSearch = false;
  private searchClassFlag = true;
  private searchHeight = 0;
  private mounted(): void {
    this.getData();
    const windheight = window.innerHeight;
    const bodyApp = windheight - 180;
    this.searchHeight = bodyApp;
    this.SearchParams.placeholderText = this.$route.params.word;
  }
  private getData(more?: string) {
    const self: any = this;
    this.$store
      .dispatch("fetchSearchIndex", {
        context: this,
        params: {
          start: 0,
          max: 4
        }
      })
      .then((result: any) => {
        if (result.value.blocks.length !== 0) {
          this.notSearch = false;
          const hotBlock = result.value.blocks[0];
          const tagBlock = result.value.blocks[1];
          if (hotBlock) {
            hotBlock.data_source = "gameDetail";
            hotBlock.blockLink = "gameDetail";
            hotBlock.ctype = "gameDetail";
            self.dataMore1 = hotBlock.more;
            self.getHotBlock = hotBlock;
            self.getHotBlock2 = hotBlock.data;
            self.rankId = hotBlock.rank_id;
          }
          if (tagBlock) {
            tagBlock.data_source = "gameDetail";
            tagBlock.blockLink = "gameDetail";
            tagBlock.moreLink = "hotTagList";
            tagBlock.block_name = tagBlock.name;
            self.dataMore2 = tagBlock.more;
            self.getTagBlock = tagBlock;
          }
        } else {
          this.notSearch = true;
        }
      })
      .catch((err: any) => {
        console.log(err);
        self.$toast.show(err.message || "服务器错误，请刷新！");
      });
  }
  private toSearchFocusDetail(): void {
    const self: any = this;
    if (!this.keyWord) {
      return;
    }
    this.SearchParams.showKeyWord = true;
    const searchId = utils.rndNum(false, 40);
    let sessionId = null;
    if (localStorage.sessionId) {
      sessionId = utils.rndNum(false, 40);
      localStorage.sessionId = sessionId;
    } else {
      sessionId = localStorage.sessionId;
    }
    this.$store
      .dispatch("fetchGetFoucSearchGame", {
        context: this,
        params: {
          q: this.keyWord,
          product: "gamesexpand",
          start: 0,
          max: 10,
          searchId,
          sessionId
        }
      })
      .then((res: any) => {
        if (JSON.stringify(res.value) === "{}") {
          self.getFocusGameBlock = [];
          return (this.notSearch = true); // 如果为空,返回false
        }
        const getFocusGameBlock = res.value.data;
        if (getFocusGameBlock) {
          this.searchState = true;
          this.focusState = true;
          getFocusGameBlock.data_source = "gameDetail";
          getFocusGameBlock.blockLink = "gameDetail";
          getFocusGameBlock.ctype = "gameDetail";
          self.dataMore3 = getFocusGameBlock.more;
          self.getFocusGameBlock = getFocusGameBlock;
        }
      })
      .catch((err: any) => {
        console.log(err);
        self.$toast.show(err.message || "服务器错误，请刷新！");
      });
  }
  get getSource(): any {
    const Block: any = this.getHotBlock;
    return Block;
  }
  private toBack(): any {
    this.$router.go(-1);
  }
  private searchBlurDetail(keywords: string) {
    const self: any = this;
    const searchId = utils.rndNum(false, 40);
    let sessionId = null;
    if (localStorage.sessionId) {
      sessionId = utils.rndNum(false, 40);
      localStorage.sessionId = sessionId;
    } else {
      sessionId = localStorage.sessionId;
    }
    this.$store
      .dispatch("fetchGetSearchGame", {
        context: this,
        params: {
          q: keywords,
          start: 0,
          max: 10,
          searchId,
          sessionId
        }
      })
      .then((res: any) => {
        const getBlurGameBlock = res.value.data.games;
        self.notSearch = false;
        self.searchState = true;
        self.focusState = false;
        if (getBlurGameBlock) {
          getBlurGameBlock.data_source = "gameList";
          getBlurGameBlock.blockLink = "gameDetail";
          getBlurGameBlock.ctype = "game";
          getBlurGameBlock.data = res.value.data.games;
          self.dataMore4 = getBlurGameBlock.more;
          self.getBlurGameBlock = getBlurGameBlock;
        } else {
          self.getBlurGameBlock = [];
        }
      })
      .catch((err: any) => {
        console.log(err);
        self.$toast.show(err.message || "服务器错误，请刷新！");
      });
  }
  private propSearchMsg(val: any) {
    const { msg, choiceBlur, clear } = val;
    this.keyWord = msg;
    if (msg) {
      this.searchClassFlag = true;
      if (choiceBlur) {
        this.searchBlurDetail(msg);
      }
    } else {
      this.searchClassFlag = false;
    }
    if (clear) {
      this.searchState = false;
      this.notSearch = false;
    }
  }
  private changeHandler() {
    this.start = this.start + 4;
    const self: any = this;
    this.$store
      .dispatch("fetchGetCollection", {
        context: this,
        rankId: self.rankId,
        params: {
          start: this.start,
          isfill: true,
          max: 4
        }
      })
      .then((result: any) => {
        if (!result.value.data) {
          self.start = 0;
          self.getHotBlock.data = self.getHotBlock2;
        } else {
          self.getHotBlock.data = [...result.value.data];
        }
      })
      .catch((err: any) => {
        self.$toast.show(err.message || "服务器错误，请刷新！");
      });
  }
}

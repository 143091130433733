


























import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import utils from "@/utils";

interface ISearchParams {
  placeholderText: string
  isFocus: boolean
  showKeyWord: boolean
}

// @Component 修饰符注明了此类为一个 Vue 组件
@Component({
  name: "searchInput"
})
export default class SearchInput extends Vue {
  public keywords: string = "";
  public showClearIcon: boolean = false;
  public isFocus: boolean = true;
  public placeholder: string = "点击输入搜索内容";
  private showPlacehoder: boolean = false;
  @Prop() private SearchParams!: ISearchParams;
  private created() {
    this.$nextTick(() => {
      (this.$refs.searchInput as Vue & { focus: any }).focus();
    });
  }

  @Watch("SearchParams.placeholderText", { immediate: true, deep: true })
  private watchSearchParamsPlace(val: string): void {
    this.placeholder = val;
    this.$emit("bindSend", { msg: val, choiceBlur: false });
  }

  @Watch("SearchParams.showKeyWord", { immediate: true, deep: true })
  private watchSearchParamsText(val: any): void {
    if (this.showPlacehoder) {
      return;
    }
    if (val) {
      this.keywords = this.placeholder;
    }
  }

  @Watch("keywords")
  private watchValue(val: string, oldval: string) {
    if (val) {
      this.showClearIcon = true;
      this.isFocus = true;
      this.showPlacehoder = true;
      this.$emit("bindSend", { msg: val, choiceBlur: true });
    } else {
      this.showClearIcon = false;
      this.isFocus = false;
      this.showPlacehoder = false;
      this.$emit("bindSend", { msg: this.placeholder, choiceBlur: false });
    }
  }
  @Emit("bindSend")
  private send(msg: string) {
    console.log("msg: ", msg);
  } // send 处理给父组件传值的逻辑

  private clearKey() {
    this.keywords = "";
    this.SearchParams.showKeyWord = false;
    this.$emit("bindSend", {
      msg: this.placeholder,
      choiceBlur: false,
      clear: true
    });
  }
}
